<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll">
		<BHLoading :show="loading" />
		<h4 class="mb-3"> Settings</h4>
		<SettingsTab @menuClick="onTabChange" :menuList="menuList" :activeTab="activeTab" width="180">
			<div v-if="activeTab === 'recaptcha'">
				<a-card title="Google reCAPTCHA">
					<a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="recaptcha" :pagination="false">
						<div slot="site_key" slot-scope="obj" class="dF aC">
							<div>{{obj.site_key}}</div>
						</div>
						<div slot="secret_key" slot-scope="obj" class="dF aC">
							<div>{{obj.secret_key}}</div>
						</div>
						<div slot="domains" slot-scope="obj" class="dF aC">
							<div>
								<a-tag v-for="(domain, index) in obj.domains" :key="index" class="mr-2">{{domain}}</a-tag>
							</div>
						</div>
						<div slot="action" slot-scope="obj" class="dF jE">
							<svg @click="editRecaptcha(obj)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 editIcon mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
							<i @click="deleteRecaptcha(obj)" class="fe fe-trash-2 deleteIcon" style="font-size: 16px; "/>
						</div>
					</a-table>

					<div @click="addNewRecaptcha" class="dF aC" style="color:var(--orange); cursor:pointer; width: 130px; margin-top:24px">
						<a-icon style="font-size:25px" type="plus-circle" class="mr-3"/>
						<div>Add Recaptcha</div>
					</div>
				</a-card>
			</div>
			<div v-else-if="activeTab === 'groups'">
				<a-card title="Groups">
					<GroupsTable />
				</a-card>
			</div>
			<div v-else-if="activeTab === 'brokerPreferences'">
				<a-card title="Broker Access Preferences">
					<a-form-model ref="brokerPreference" :model="brokerPreference">
						<a-row :gutter="16" class="mt-4">
							<a-col :span="8">
								<a-form-model-item prop="worksheet" label="Select a Worksheet">
									<a-select v-model="brokerPreference.worksheet" size="large"
										placeholder="Select a worksheet">
										<a-select-option v-for="worksheet in worksheets" :key="worksheet.id"
											:value="worksheet.id">{{ worksheet.name }}</a-select-option>
									</a-select>
								</a-form-model-item>
							</a-col>
						</a-row>
						<div class="w-full dF jE">
							<a-button type="primary" @click="saveBrokerPreference" class="mt-4" size="large">SAVE</a-button>
						</div>
					</a-form-model>
				</a-card>
			</div>
		</SettingsTab>
		<a-modal :width="'510px'" centered :header="null" :footer="null" :visible="showRecaptchaModal" @cancel="cancelRecaptcha">
			<div>
				<h5>{{ newRecaptcha.editing ? 'Edit Recaptcha' : 'Add New Recaptcha' }}</h5>
				<a-row :gutter="16" class="mt-4">
					<a-form-model ref="newRecaptcha" :model="newRecaptcha">
						<a-col :span="24">
							<a-form-model-item prop="site_key" label="Site Key" required :rules="req('Please enter the site key')">
								<a-input v-model="newRecaptcha.site_key" placeholder="Enter the site key"></a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="24">
							<a-form-model-item prop="secret_key" label="Secret Key" required :rules="req('Please enter the secret key')">
								<a-input v-model="newRecaptcha.secret_key" placeholder="Enter the secret key"></a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="24">
							<a-form-model-item prop="domains" label="Domains" required :rules="req('Please enter at least one domain')">
								<a-select v-model="newRecaptcha.domains" mode="tags" style="width: 100%" placeholder="Domains">
								</a-select>
							</a-form-model-item>
						</a-col>
					</a-form-model>
				</a-row>
				<div class="dF mt-3" style="justify-content:flex-end">
					<a-button @click="cancelRecaptcha" size="large" class="cancel-button">CANCEL</a-button>
					<a-button @click="submitRecaptcha" type="primary" size="large" class="ml-3">{{ newRecaptcha.editing ? 'UPDATE' : 'CREATE' }}</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { formatDate } from "bh-mod";
import SettingsTab from 'bh-mod/components/common/SettingsTab'
import GroupsTable from '@/components/forms/GroupsTable'
import BHLoading from 'bh-mod/components/common/Loading';

export default {
	components: {
		SettingsTab, BHLoading, GroupsTable
	},
	data() {
		return {
			timeNow: Date.now(),
			activeTab: 'recaptcha',
			loading: false,
			brokerPreference: {
				worksheet:''
			},
			recaptcha:[],
			newRecaptcha:{
				editing:false,
                site_key:'',
				secret_key:'',
				domain:[],
				id:0
            },
			showRecaptchaModal:false,
			columns:[
                {
                    title: 'Site key',
                    key: 'site_key',
                    scopedSlots: { customRender: 'site_key' },
                },
				{
                    title: 'Secret key',
                    key: 'secret_key',
                    scopedSlots: { customRender: 'secret_key' },
                },
				{
                    title: 'Domains',
                    key: 'domains',
                    scopedSlots: { customRender: 'domains' },
                },
                {
                    title: 'Actions',
                    key: 'action',
                    align: 'right',
                    scopedSlots: { customRender: 'action' },
                },
            ],
			menuList: [
				{
					label: 'General Settings', id: 'sub1', children: [{ label: 'reCAPTCHA', id: 'recaptcha' },{ label: 'Groups', id: 'groups' }]
				},
				{
					label: 'Broker Access', id: 'sub2', children: [{ label: 'Preferences', id: 'brokerPreferences' }]
				},
			],
		};
	},
	computed: {
		instance() {
			return this.$store.state.instance;
		},
		brokerInstance(){
			return this.instance && this.instance.package && this.instance.package.itemCode == 'PA-BRKR'
		},
		forms(){
			return Object.values(this.$store.state.forms.allForms)
		},
		worksheets(){
			return this.forms.filter(x => x.type == 'worksheet')
		}
	},
	methods: {
		formatDate,
		req: msg => ({ required: true, message: msg }),
		onTabChange(item) {
			this.activeTab = item;
		},
		addNewRecaptcha() {
			console.log('ADDING NEW RECAPTCHA...')
			this.showRecaptchaModal = true
		},
		editRecaptcha(item){
			console.log('EDITING RECAPTCHA...', item)
			this.newRecaptcha = {
				editing:true,
				site_key:item.site_key,
				secret_key:item.secret_key,
				domains:item.domains,
				id:item.id
			}
			this.showRecaptchaModal = true
		},
		submitRecaptcha() {
			console.log('SUBMITTING RECAPTCHA...')
            this.$refs['newRecaptcha'].validate(valid => {
                if (valid) {
                    console.log('newRecaptcha', this.newRecaptcha)
                    let contains = false
                    this.recaptcha.forEach( x => {
                        console.log('X')
                        if (x.id != this.newRecaptcha.id && (x.site_key.toLowerCase() == this.newRecaptcha.site_key.toLowerCase() || x.secret_key.toLowerCase() == this.newRecaptcha.secret_key.toLowerCase())){
                            contains = true
                            return this.$message.error('Recaptcha key already exists!')
                        }
                    })
                    if (!contains){
						this.loading = true
						if (this.newRecaptcha.editing){
							let settings = JSON.parse(JSON.stringify(this.$store.state.forms.allSettings.app)) || { options: { 'google_recaptcha': [] } }
							this.recaptcha.forEach((recaptcha, index) => {
								if (recaptcha.id == this.newRecaptcha.id){
									let sendObj = {
										site_key:this.newRecaptcha.site_key,
										secret_key:this.newRecaptcha.secret_key,
										domains:this.newRecaptcha.domains,
										id:this.newRecaptcha.id
									}
									this.recaptcha[index] = sendObj;
								}
							})
							settings.options.google_recaptcha = this.recaptcha;

							this.$api.post('/settings/:instance/forms', settings).then(({ data }) => {
								this.$store.commit('UPDATE_ALLSETTINGS', data)
								this.loading = false
								this.cancelRecaptcha()
								this.$message.success('reCAPTCHA settings saved!')
							}).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
									this.loading = false
								}
							})
							
						} else {
							let settings = JSON.parse(JSON.stringify(this.$store.state.forms.allSettings.app)) || { options: { 'google_recaptcha': [] } }
							let sendObj = {
								site_key:this.newRecaptcha.site_key,
								secret_key:this.newRecaptcha.secret_key,
								domains:this.newRecaptcha.domains,
								id:Date.now()
							}
							settings.options.google_recaptcha.push(sendObj);
							this.recaptcha.push(sendObj);
							settings.options.google_recaptcha = this.recaptcha;

							this.$api.post('/settings/:instance/forms', settings).then(({ data }) => {
								this.$store.commit('UPDATE_ALLSETTINGS', data)
								this.loading = false
								this.cancelRecaptcha()
								this.$message.success('reCAPTCHA settings saved!')
							}).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
									this.loading = false
								}
							})
						}
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			})
		},
		cancelRecaptcha(){
			console.log('CANCEL RECAPTCHA...')
            this.$refs['newRecaptcha'].resetFields()
			this.newRecaptcha = {
				editing:false,
                site_key:'',
				secret_key:'',
				domain:[],
				id:0
            },
            this.showRecaptchaModal = false
        },
		deleteRecaptcha(item) {
            let self = this
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete Recaptcha')
            }
            this.$confirm({
				title: "Delete Recaptcha",
				content: h => <div>Do you want to delete this recaptcha?</div>,
				okText: 'Delete',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				onOk() {
					console.log('DELETING RECAPTCHA...', item)
					self.loading = true;
					let settings = JSON.parse(JSON.stringify(self.$store.state.forms.allSettings.app)) || { options: { 'google_recaptcha': [] } }
					self.recaptcha.forEach((recaptcha, index) => {
						if (recaptcha.id == item.id){
							self.recaptcha.splice(index, 1)
						}
					})
					settings.options.google_recaptcha = self.recaptcha;
					self.$api.post('/settings/:instance/forms', settings).then(({ data }) => {
						self.$store.commit('UPDATE_ALLSETTINGS', data)
						self.loading = false
						self.cancelRecaptcha()
						self.$message.success('reCAPTCHA successfully deleted!')
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(this.$err(err))
							self.loading = false
						}
					})
				},
				onCancel() {
					console.log('Cancel')
				}
            })
        },
		saveBrokerPreference(){
			this.$refs.brokerPreference.validate(valid => {
				if (valid) {
					let settings = JSON.parse(JSON.stringify(this.$store.state.forms.allSettings.app)) || { options: { 'google_recaptcha': [], 'broker_access': { 'worksheet': "" } } }
					if (settings && settings.options && settings.options.broker_access && settings.options.broker_access.worksheet){
						settings.options.broker_access.worksheet = this.brokerPreference.worksheet
					} else {
						settings.options.broker_access = { 'worksheet': this.brokerPreference.worksheet }
					}
					
					this.loading = true
					this.$api.post('/settings/:instance/forms', settings).then(({ data }) => {
						this.$store.commit('UPDATE_ALLSETTINGS', data)
						this.loading = false
						this.$message.success('Broker Access settings saved!')
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
				}
			})
		}
	},
	created() {
		this.$store.commit('PREVIOUS_ROUTE', '/surveys')
		this.$store.commit('FORM_TYPE', { value: 'Settings' })
		let settings = JSON.parse(JSON.stringify(this.$store.state.forms.allSettings.app)) || { options: { 'google_recaptcha': [], 'broker_access': { 'worksheet': "" } } }
		if (settings.options.google_recaptcha) {
			this.recaptcha = settings.options.google_recaptcha
		}
		if (settings && settings.options && settings.options.broker_access && settings.options.broker_access.worksheet) {
			this.brokerPreference.worksheet = settings.options.broker_access.worksheet
		}

		if (this.brokerInstance){
			this.menuList = this.menuList.filter(item => item.id !== 'sub2')
		}
	},
};
</script>

<style lang="scss" scoped>
    .editIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .editIcon:hover {
        color: var(--orange);
        transition:color .1s ease-in;
    }
    .deleteIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .deleteIcon:hover {
        color: var(--danger);
        transition:color .1s ease-in;
    }
</style>
